/**
 * 景品のギフトコードを送信
 * @param userId
 * @returns {Promise<any>}
 */
const sendGiftCode = async (userId) => {
  if (process.env.MODE === 'development') {
    // mock
    console.log('ギフト送信結果 モック取得')
    return {
      status: true,
    }
  } else {
    console.log('ギフトコード送信のリクエスト開始')
    const response = await fetch(`${process.env.API_BASE_URL}/sendCode`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'x-api-key': process.env.API_BASE_KEY,
      },
      body: JSON.stringify({
        userId: userId,
      }),
    })
    if (response.status !== 200) {
      console.log(response.status)
      alert('コードが送信できません' + response.statusText)
    }
    const json = await response.json()
    console.log('↓ギフトコード送信のレスポンス完了')
    console.table(json)

    return json
  }
}

export { sendGiftCode }
