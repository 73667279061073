import './polyfill'
import 'what-input'

import liff from '@line/liff'
import clone from 'just-clone'
import { getOmikuji } from './lib/getOmikuji'
import { getOmikujiHistory } from './lib/getOmikujiHistory'
import { sendGiftCode } from './lib/sendGiftCode'
import { show, hide, showStartArea, showVideoArea, showResultArea } from './lib/display'
import { isLastDay } from './lib/isLastDay'

// おみくじ実行の連打防止用フラグ
let isAPICallable = true

const omikujiRoman = {
  大吉: 'daikichi',
  中吉: 'chuukichi',
  小吉: 'shoukichi',
  吉: 'kichi',
  末吉: 'suekichi',
}

const omikujiPrize = {
  大吉: 10000,
  中吉: 5000,
  小吉: 1000,
  吉: 100,
  末吉: 0,
}

const displayClass = {
  beforeApply: '-notApplied',
  afterApply: '-applied',
  lastDay: '-lastDay',
}

const suggestMessage = {
  beforeApply: '',
  afterApply: '明日もまたチャレンジしてね！',
  afterApplyLastDay: '参加ありがとう！元日SPも見逃すな！',
}
/**
 * 画面内で使用する要素を取得します。
 */
const el = {
  body: document.getElementsByTagName('body')[0],
  // スタート画面
  startArea: document.getElementById('startArea'),
  announce: document.getElementById('announce'),
  drawOmikuji: document.getElementById('drawOmikuji').closest('#drawOmikuji'),
  seeResult: document.getElementById('seeResult'),
  topGiftArea: document.getElementById('topGiftArea'),
  topFortuneImage: document.getElementById('topFortuneImage'),
  topGiftImage: document.getElementById('topGiftImage'),
  // 動画画面
  videoArea: document.getElementById('videoArea'),
  video: document.getElementById('video'),
  // 結果画面
  resultArea: document.getElementById('resultArea'),
  fortune: document.getElementById('fortune'),
  miss: document.getElementById('miss'),
  notLastDayArea: document.getElementById('notLastDayArea'),
  lastDayArea: document.getElementById('lastDayArea'),
  fortuneImage: document.getElementById('fortuneImage'),
  giftImage: document.getElementById('giftImage'),
  receiveCode: document.getElementById('receiveCode'),
  receiveCodeImage: document.getElementById('receiveCodeImage'),
  returnTop: document.getElementById('returnTop'),
  share: document.getElementById('share'),
}

/**
 * 外部から取得したデータ
 */
const state = {
  line: {}, // LINEから取得したユーザーのプロフィール情報
  omikujiHistory: {}, // AWSから取得した`canOmikuji`と`fortune`データ
  omikuji: {}, // AWSから取得したおみくじ結果データ
}

/**
 * LIFFを初期化します。
 */
liff
  .init({
    liffId: process.env.LIFF_ID,
  })
  .then(() => {
    // LIFFブラウザでは`liff.init()`時に実行される
    if (!liff.isLoggedIn() && process.env.MODE !== 'development') {
      liff.login(process.env.LIFF_URL)
      return
    }
    console.log('環境：' + process.env.MODE)
    console.log('LIFFの初期化完了')
    initializeLiff()
  })
  .catch((err) => {
    console.error('LIFFの初期化に失敗しました')
    console.error(err)
  })

/**
 * LINEからプロフィール情報を取得します。
 * 外部ブラウザからアクセスした場合は、ダミー情報を渡します。
 */
const initializeLiff = () => {
  if (!liff.getLineVersion()) {
    console.log(`外部ブラウザからのアクセス`)
    alert(`LINEからアクセスしてください。`)
  }

  if (liff.getLineVersion()) {
    console.log(`LINEアプリからのアクセス`)
    liff
      .getProfile()
      .then((profile) => {
        state.line = clone(profile)
        console.log(`↓state.lineにデータを保存しました`)
        console.log(state.line)
        return state.line
      })
      .then(() => {
        initializeApp()
      })
      .catch((err) => {
        console.log('getProfile()に失敗しました: ', err)
      })
  }
}

/**
 * ユーザーデータを取得して、初期画面を表示します。
 */
const initializeApp = async () => {
  console.log('↓state.line.userId')
  console.log(state.line.userId)
  registerButtonHandlers()
  await getOmikujiHistory(state.line.userId)
    .then((history) => {
      state.omikujiHistory = clone(history)
      console.log(`↓state.omikujiHistoryに値を保存`)
      console.log(state.omikujiHistory)
    })
    .then(() => {
      insertStartValues()
      displayStartElements()
      showStartArea(true)
    })
}

/**
 * おみくじを引けるか？
 */
const canDrawOmikuji = () => {
  return !!state.omikujiHistory.canOmikuji
}

/**
 * スタート画面に値を挿入します。
 */
const insertStartValues = () => {
  console.log(`スタート画面に値を挿入開始`)

  if (!canDrawOmikuji()) {
    console.log('スタート画面：参加済み')
    const omikujiKey = omikujiRoman[state.omikujiHistory.result.fortune]
    let missSuffix = ''
    el.body.classList.add(displayClass.afterApply)
    el.body.classList.remove(displayClass.beforeApply)
    el.drawOmikuji.disabled = true
    el.drawOmikuji.firstChild.setAttribute('src', './../assets/img/btn_txt_participated.png')
    if (state.omikujiHistory.result.fortune === '末吉') {
      el.topGiftArea.setAttribute('hidden', true)
      document.getElementById('suggestReceive').setAttribute('hidden', true)
      const nowDate = new Date().getDate()
      missSuffix = '_' + nowDate.toString()
      console.log(`画像用日付: ${nowDate}`)
      console.log('末吉画像キー：' + omikujiKey + missSuffix)
    } else {
      el.topGiftArea.removeAttribute('hidden')
      el.topGiftImage.setAttribute('src', './../assets/img/gift_' + omikujiKey + '.png')
    }
    el.topFortuneImage.setAttribute(
      'src',
      './../assets/img/fortune_' + omikujiKey + missSuffix + '.png',
    )
    el.topFortuneImage.setAttribute('alt', state.omikujiHistory.result.fortune)
  }

  if (!canDrawOmikuji() && isLastDay()) {
    console.log('スタート画面：参加済み・最終日')
    el.body.classList.add(displayClass.lastDay)
    el.announce.innerHTML = suggestMessage.afterApplyLastDay
  }

  if (!canDrawOmikuji() && !isLastDay()) {
    console.log('スタート画面：参加済み・最終日ではない')
    el.announce.innerHTML = suggestMessage.afterApply
  }

  if (canDrawOmikuji()) {
    console.log('参加していない')
    el.body.classList.remove(displayClass.afterApply)
    el.body.classList.add(displayClass.beforeApply)
    el.announce.innerHTML = suggestMessage.beforeApply
    el.drawOmikuji.disabled = false
    el.drawOmikuji.firstChild.setAttribute('src', './../assets/img/btn_txt_start.png')
    el.topFortuneImage.setAttribute('src', './../assets/img/blank.png')
    el.topGiftImage.setAttribute('src', './../assets/img/blank.png')
  }

  console.log(`スタート画面に値を挿入終了`)
}

/**
 * スタート画面の表示を切り替えます。
 */
const displayStartElements = async () => {
  console.log('スタート画面：表示の切り替え開始')

  if (canDrawOmikuji()) {
    console.log(`スタート画面：今日はまだおみくじを引いていない`)
    show(el.drawOmikuji)
    hide(el.seeResult)
  }

  if (!canDrawOmikuji()) {
    console.log(`スタート画面：今日はすでにおみくじを引いている`)
    show(el.drawOmikuji)
    show(el.seeResult)
  }

  console.log('スタート画面：表示の切り替え終了')
}

/**
 * 「おみくじを引く」ボタン
 */
const onClickOmikuji = async () => {
  const userId = state.line.userId
  await getOmikuji(userId)
    .then((omikuji) => {
      state.omikuji = clone(omikuji)
      console.log('↓state.omikuji')
      console.log(state.omikuji)
    })
    .then(() => {
      document.body.scrollTop = document.documentElement.scrollTop = 0
      setOmikujiContents()
      showStartArea(false)
      showVideoArea(true)
      videoPlaybackEnd()
      isAPICallable = true
    })
}

/**
 * おみくじ関連のコンテンツを設定
 */
const setOmikujiContents = () => {
  console.log(`動画・結果画面・TOPに値を挿入開始`)
  // 動画をセット
  const omikujiResult = state.omikuji.result.fortune
  let imageKey = omikujiRoman[omikujiResult]
  let videoKey = omikujiRoman[omikujiResult]

  // 結果に応じた動画と画像をセット
  if (state.omikuji.result.fortune === '末吉') {
    // はずれは日ごとに異なるものをセット
    const nowDate = new Date().getDate()
    if (nowDate < 28 && nowDate > 1) {
      videoKey += '_28'
      imageKey += '_28'
    } else {
      videoKey += `_${nowDate}`
      imageKey += `_${nowDate}`
    }
    console.log(`日付: ${nowDate}`)
    console.log('末吉動画キー：' + videoKey)
    console.log('末吉結果画像キー：' + imageKey)
    el.giftImage.setAttribute('src', './../assets/img/blank.png')
    el.fortune.setAttribute('hidden', true)
    el.miss.removeAttribute('hidden')
    // 最終日か否かで変わる文言部分の表示を制御
    if (isLastDay()) {
      el.notLastDayArea.setAttribute('hidden', true)
      el.lastDayArea.removeAttribute('hidden')
    } else {
      el.notLastDayArea.removeAttribute('hidden')
      el.lastDayArea.setAttribute('hidden', true)
    }
  } else {
    el.giftImage.setAttribute('src', './../assets/img/gift_' + imageKey + '.png')
    el.fortune.removeAttribute('hidden')
    el.miss.setAttribute('hidden', true)
  }
  console.log('videoKey:' + videoKey)
  el.video.setAttribute('src', `./../assets/video/${videoKey}.mp4`)
  el.video.load()
  el.fortuneImage.setAttribute('src', './../assets/img/fortune_' + imageKey + '.png')
  el.fortuneImage.setAttribute('alt', state.omikuji.result.fortune)

  // twitterシェア用テキスト設定
  const resultText =
    `「${omikujiResult}」でした` +
    (omikujiResult === '末吉'
      ? '🐯 毎日チャレンジでAmazonギフト券GETのチャンス❗%0a'
      : `🎉 Amazonギフト券 ${omikujiPrize[omikujiResult]}円分をその場でGET❗%0a`)
  const tweetText = `みんなも🎍ジョブチューンおみくじ🎍にチャレンジ👇👇%0alin.ee/utoeVjo%0a『%23ジョブチューン コンビニ3社 元旦SP』1月1日放送📺%0a%23TBS%0a%23ジョブチューンおみくじ`
  const tweetContent = `https://twitter.com/intent/tweet?text=${resultText}${tweetText}`
  el.share.setAttribute('href', tweetContent)
  console.log(`動画・結果画面・TOPに値を挿入終了`)
}

/**
 * 動画の再生開始、再生終了後は応募画面に切り替える
 */
const videoPlaybackEnd = () => {
  const video = el.video
  console.log(`動画の再生開始`)
  video.play()
  video.onerror = function () {
    console.log('Error ' + video.error.code + '; details: ' + video.error.message)
  }
  video.addEventListener('ended', () => {
    setTimeout(() => {
      console.log(`動画の再生終了`)
      console.log(state.omikuji.result.fortune)
      showVideoArea(false)
      showResultArea(true)
    }, 1000)
  })
}

/**
 * 利用者が操作する処理を登録します。
 */
const registerButtonHandlers = () => {
  el.drawOmikuji.addEventListener('click', function () {
    console.log(`「おみくじを引く」ボタンをクリック`)
    if (isAPICallable) {
      onClickOmikuji()
    }
    isAPICallable = false
  })

  /**
   * 「結果をもう一度見る」ボタン
   */
  el.seeResult.addEventListener('click', function () {
    console.log(`「結果をもう一度見る」ボタンをクリック`)
    if (isAPICallable) {
      onClickOmikuji()
    }
    isAPICallable = false
  })

  /**
   * 「受け取る」ボタン
   */
  el.receiveCode.addEventListener('click', function () {
    console.log(`「受け取る」ボタンをクリック`)
    if (liff.isInClient() || 1 !== 0) {
      // 連打防止のため先にボタンを非活性化
      el.receiveCode.disabled = true
      el.receiveCodeImage.setAttribute('src', './../assets/img/btn_txt_sent.png')
      sendGiftCode(state.line.userId).then((result) => {
        console.log(`↓ギフト送信結果`)
        console.table(result)
      })
    }
  })

  /**
   * 「TOPにもどる」ボタン
   */
  el.returnTop.addEventListener('click', function () {
    console.log(`「TOPにもどる」ボタンをクリック`)
    getOmikujiHistory(state.line.userId)
      .then((history) => {
        state.omikujiHistory = clone(history)
        console.log(`↓state.omikujiHistoryに押下タイミングでの値を再設定`)
        console.log(state.omikujiHistory)
      })
      .then(() => {
        insertStartValues()
        displayStartElements()
        showStartArea(true)
        el.receiveCode.disabled = false
        el.receiveCodeImage.setAttribute('src', './../assets/img/btn_txt_get.png')
        document.body.scrollTop = document.documentElement.scrollTop = 0
      })
  })

  /**
   * 「結果をツイート」ボタン
   */
  el.share.addEventListener('click', function () {
    console.log(`「結果をツイート」ボタンをクリック`)
  })
}
